import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import { CIcon } from '@coreui/vue'
import { COffcanvas, COffcanvasTitle, COffcanvasBody, COffcanvasHeader, CCloseButton, CToast, CToaster } from '@coreui/vue'
import OneSignalVue from 'onesignal-vue'


Vue.config.performance = true
Vue.use(CoreuiVue, OneSignalVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  CIcon,
  COffcanvas,
    COffcanvasTitle,
    COffcanvasBody,
    COffcanvasHeader,
    CCloseButton,
    CToast,
    CToaster,
    components: {
      App
    },
  template: '<App/>',
  beforeMount() {
    var OneSignal = window.OneSignal || [];
    OneSignal.push(() => {
      OneSignal.init({ appId: "5e8806a8-a2c3-4d38-9a6f-b7a1b020e6e0"
    });
  });
  },
})

