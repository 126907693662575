<template>
  <div>
  <div v-if="loading">
  <preloader />
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
  
 
  </div>
</template>

<script>
import Preloader from './Preloader.vue';

export default {
  name: 'App',
  components: {Preloader} ,
  data () {
    return {
      loading: true,
    }
  },
  
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 3000);
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

</style>
