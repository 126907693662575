<template>
    <div class="preloader" id="pre">
        <img src="./assets/ibicon.png" height="100px" style="padding-top: 20px;" alt=""> <br> 
    <CSpinner size="md" color="primary" /> please wait application is loading.....
    </div>
</template>

<script>
export default {
    name: 'Preloader',
}
</script>

<style scoped>
#pre {
    height: 100%;
    width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
   margin-top: 150px;
}
    .preloader {
  position: relative;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin:50px; */
  padding-top: 20px;
}
</style>