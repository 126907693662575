import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  notifications: []
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  addNotifications(state, notifications) {
    state.notifications.push(notifications);
  },
  removeNotifications(state, notificationId) {
    state.notifications = state.notifications.filter(
      (notifications) => notifications.id !== notificationId
    );
  },
}




export default new Vuex.Store({
  state,
  mutations,
});


//Auth

// import AuthAPIservices from '../../../APIs/AuthAPIservices';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const StoreAuth = {
  namespaced: true,
  state: {
    initialState,
    // notifications: [],
  },
  mutations: {
    LOGIN_SUCCESSFUL(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    LOGIN_FAILED(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    LOGOUT_SUCCESSFUL(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    addNotifications(state, notifications) {
      state.notifications.push(notifications);
    },
    removeNotifications(state, notificationId) {
      state.notifications = state.notifications.filter(
        (notifications) => notifications.id !== notificationId
      );
    }
  },

  actions: {
    async login({ commit }, user) {
      try {
        const response = await AuthAPIservices.login(user);
        commit('LOGIN_SUCCESSFUL', response);
        return Promise.resolve(response);
      }
      catch (error) {
        commit('LOGIN_FAILED');
        return Promise.reject(error);
      }
    },
    async logout({ commit }) {
      const response = await AuthAPIservices.logout();
      commit('LOGOUT_SUCCESSFUL');
      return Promise.resolve(response);
    },

    // Action to add a notification
  async addNotificationAction({ commit }, notifications) {
    commit('addNotifications', notifications);
  },

  // Action to remove a notification
  async removeNotificationAction({ commit }, notificationId) {
    commit('removeNotifications', notificationId);
  },
  }
};


// In your Vuex store module



